///////////////////////////////////
// Navigation
///////////////////////////////////

// ******************************
// Hamburger icon
// ******************************

let hamburgerIcon = $('.hamburger');

// Hamburger icon active
hamburgerIcon.on('click', function(e){
    e.preventDefault();
    hamburgerIcon.toggleClass('hamburger--active');
    subList.slideUp();
    dropDown.removeClass('navigation__link--drop-down-active');
    dropDown.removeClass('navigation__link--active');
})


// ******************************
// Navigation drop down (accordion)
// ******************************

let dropDown = $('.navigation__link--drop-down');
let subList = $('.navigation__sub-list');

// Accordion
dropDown.on('click', function(e) {
    e.preventDefault();
    
    subList.slideToggle(500);
    $(this).toggleClass('navigation__link--drop-down-active');
    $(this).toggleClass('navigation__link--active');
});

// ******************************
// Navigation open/close
// ******************************

let navigation = $('.navigation');
let navigationBtn = $('.navigation .btn--close');

// Open navigation
hamburgerIcon.on('click', function(e){

    navigation.toggleClass('navigation--open');
    $('body').toggleClass('body--hiddne');
    $('.header').toggleClass('header--navigation-active');
});

// Close navigation
navigationBtn.on('click', function(e){
    e.preventDefault();

    subList.slideUp();
    hamburgerIcon.removeClass('hamburger--active');
    navigation.removeClass('navigation--open');
    $('body').removeClass('body--hiddne');
    $('.header').removeClass('header--navigation-active');
});
